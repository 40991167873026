<template>
  <div class="select-popup">
    <div class="popup-box" :style="{ minWidth: size || '240px' }">
      <slot></slot>
    </div>
    <div class="popup-overlay" @click="closePopup(false)"></div>
    <div class="popup-loading" v-show="showLoading">
      <app-loader></app-loader>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/main";

export default {
  name: "selectPopup",
  props: ["size", "closePopup", "showLoading"],
  components: {
    "app-loader": () =>
      import(
        /* webpackChunkName: "appLoader" */ "@/components/Shared/templates/appLoader.vue"
      )
  }
};
</script>

<style lang="scss">
@import "~sass/modules/_variables.scss";
@import "~sass/modules/_mediaQueries.scss";
.select-popup {
  position: relative;
  .popup-box {
    position: relative;
    background: $clr-bg-black-1 0% 0% no-repeat padding-box;
    // padding: 20px 20px;
    border-radius: 12px;
    z-index: 8999;
    padding: 15px;
  }
  .popup-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 8998;
  }
}

.popup-loading {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: $clr-dark-hlt-2;
  z-index: 9999;

  .vlt-loading {
    position: relative;
    top: 50%;
  }
}

//(max-width:480px)

@include breakpoint(max480){
  .menu-popup.select-popup{
    position:fixed !important;
    top: $header-total-height !important;
    left:0 !important;
    height:calc(100vh - #{$header-total-height});
    width:100vw;
    overflow-y:auto;

    &::-webkit-scrollbar-track{
      background:$clr-bg-black-1;
    }
   .popup-box{
     border-radius:0;
     min-height:100%;
   }
   .popup-overlay{
     display: none;
   }
  }
}
</style>
